import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SEO, GeneralJumbotron, SummaryTemplate, CallToAction } from '../components';
import { MainLayout } from '../layouts';
import { TransportationImagesQuery } from '../shared/interfaces';
import * as sharedStyles from '../styles/shared.module.scss';

const TransportationPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<TransportationImagesQuery>(graphql`
        query {
            transportationBackgroundImage: file(name: {eq: "transportationBackground"}, extension: {eq: "png"}) {
                publicURL
            }
            transportationSummaryImage: file(name: {eq: "transportationSummary"}, extension: {eq: "png"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | Transportation"/>
            <GeneralJumbotron
                title="Transportation"
                backgroundImageURL={queryResult?.transportationBackgroundImage.publicURL}
                backgroundText="Transportation"
            />
            <SummaryTemplate image={queryResult?.transportationSummaryImage.publicURL}>
                <h2>{`Best Transportation Provider`}</h2>
                <p>
                    {`
                        Transporting your clients to and from doctor appointments can be quite costly, and most of the time, it’s out of your own pocket. That’s why we’ve contracted with a national transportation provider that has a proven track record of being the best in the industry. You can rest assured that your clients will experience safe, reliable, and on-time transportation.
                    `}
                </p>
                <div className={sharedStyles.listWrapper}>
                    <p>{`Key Solutions Offerings:`}</p>
                    <ul>
                        <li>{`Rideshare`}</li>
                        <li>{`Ambulatory`}</li>
                        <li>{`Wheelchair`}</li>
                        <li>{`Stretcher`}</li>
                        <li>{`Basic/advanced life support`}</li>
                        <li>{`Airlift services`}</li>
                        <li>{`Commercial airline transport`}</li>
                        <li>{`Hotel reservations`}</li>
                        <li>{`And so much more!`}</li>
                    </ul>
                </div>
            </SummaryTemplate>
            <CallToAction/>
        </MainLayout>
    );
}

export default TransportationPage;
